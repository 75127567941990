import { createApp } from 'vue'
import App from './App.vue'
import router from '@/router/index';
import VueFullpage from 'vue-fullpage.js';
import ElementPlus from 'element-plus';
import 'element-plus/theme-chalk/index.css';
import locale from 'element-plus/lib/locale/lang/zh-cn';

const app = createApp(App);
app.use(router);
app.use(ElementPlus, {locale});
app.use(VueFullpage);
app.mount('#app');
