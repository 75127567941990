<template>
	<full-page :options="options" ref="fullpage">
		<div class="section" data-menuanchor="one">
			<div class="section_box flex flex-between">
				<div class="section_left_box flex flex-column flex-left flex-center">
					<p>高效便捷，上门私教</p>
					<span>无需出门，多样课程立即定制<br />在家就能享受健身房待遇</span>
				</div>
				<img src="@/assets/imgs/img1.png" alt="">
			</div>
		</div>
		<div class="section" data-menuanchor="two">
			<div class="section_box flex flex-between">
				<div class="section_left_box flex flex-column flex-left flex-center">
					<p>一键连麦，线上私教</p>
					<span>社恐专属，一对一直播连麦，让私教<br />成为你的健身搭子</span>
				</div>
				<img src="@/assets/imgs/img2.png" alt="">
			</div>
		</div>
		<div class="section" data-menuanchor="three">
			<div class="section_box flex flex-between">
				<div class="section_left_box flex flex-column flex-left flex-center">
					<p>多元健身，品质生活</p>
					<span>精准运动数据，多样饮食对比，超全3D动作库<br />即刻开启科学健身</span>
				</div>
				<img src="@/assets/imgs/img3.png" alt="">
			</div>
		</div>
		<div class="section" data-menuanchor="four">
			<div class="section_box flex flex-between">
				<div class="section_left_box flex flex-column flex-left flex-center">
					<p>知识分享，优质社区</p>
					<span>记录汗水、交流经验<br />用热爱滋养每一次蜕变</span>
				</div>
				<img src="@/assets/imgs/img4.png" alt="">
			</div>
		</div>
		<FooterVue class="section section1" data-menuanchor="five"></FooterVue>
	</full-page>

	<RightCode v-show="show"></RightCode>
	<div class="mpCode_box" v-show="show">
		<img class="code" :src="imgs || require('@/assets/imgs/footerMpCode.png')" alt="">
		<p class="codeFont" v-if="!imgs">扫码使用Ragana小程序</p>
	</div>
</template>

<script setup>
	import {
		reactive,
		ref,
		defineEmits,
		onMounted,
		watch
	} from 'vue';
	import FooterVue from '../components/FooterVue.vue';
	import RightCode from '../components/RightCode.vue';
	import {
		useRouter
	} from 'vue-router'
	const router = useRouter()
	const show = ref(true);
	const fullpage = ref();
	const changeColor = defineEmits(['changeColor']);

	const options = {
		// licenseKey: "OPEN-SOURCE-GPLV3-LICENSE",
		licenseKey: "OPEN-SOURCE-GPLV3-LICENSE",
		anchors: ['one', 'two', 'three', 'four', 'five', 'six'],
		// menu: '.section',
		lockAnchors: true,
		navigation: false, //显示导航
		navigationPosition: 'right',
		verticalCentered: false,
		scrollOverflow: false,
		onLeave: (index, nextIndex) => {
			if (nextIndex.index == 4) {
				show.value = false
			} else {
				show.value = true
			}
			if (nextIndex.index === 0) {
				changeColor('changeColor', '#fff')
			} else {
				changeColor('changeColor', '#000')
			}
		},
		afterRender: () => {
			setTimeout(() => {
				var href = window.location.href.split('?')[1]

				if (fullpage.value.api) {
					fullpage.value.api.moveTo(href, 0);
				}


			}, 200)
		}
	}
</script>

<style lang="scss" scoped>
	.fp-overflow {
		height: 100%;
	}

	.one-footer {
		position: inherit;
	}

	.code_box::v-deep {
		bottom: 250px;
	}

	.mpCode_box {
		position: fixed;
		right: 30px;
		bottom: 60px;


		.codeFont {
			position: absolute;
			bottom: 12px;
			font-size: 12px;
			font-family: SourceRegular;
			font-weight: 400;
			color: #333333;
			width: 100%;
			text-align: center;
		}
	}

	.section {
		background: url(@/assets/imgs/bg1.png) no-repeat center;
		background-size: 100%;
		background-color: #fff;
		position: relative;

		&:nth-child(2n) {
			background: url(@/assets/imgs/bg2.png) no-repeat center;
			background-size: 100%;
			background-color: #FDF8F8;

			.section_box {
				flex-direction: row-reverse;

				img {
					margin-left: 24px;
					margin-right: 0;
				}

				.section_left_box {
					align-items: flex-end;
					margin-right: 180px;
					margin-left: 0;

					span {
						text-align: right;
					}
				}
			}
		}

		&.section1 {
			background: none;
		}

		.section_box {
			align-items: end;
			overflow: hidden;
			height: 100%;

			.section_left_box {
				height: 100vh;
				margin-left: 180px;

				p {
					font-size: 60px;
					font-family: SourceBold;
					font-weight: bold;
					color: #000000;
					margin-top: 0;
					margin-bottom: 20px;
				}

				span {
					font-size: 24px;
					font-family: SourceNormal;
					font-weight: 400;
					color: #666666;
				}
			}

			img {
				width: 44%;
				height: 94.4%;
				margin-right: 24px;
			}
		}
	}

	.fp-right,
	.fp-watermark {
		display: none;
	}
</style>