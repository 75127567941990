import {
	createRouter,
	createWebHashHistory
} from 'vue-router';

import IndexPage from '@/pages/homePage.vue';
import ProductInfo from '@/pages/ProductInfo.vue';
import CoachInfo from '@/pages/CoachInfo.vue';
import AboutInfo from '@/pages/AboutInfo.vue';
const router = createRouter({
	// 3.1 通过 history 属性，指定路由的工作模式
	history: createWebHashHistory(),
	// 3.2 通过 routes 数组，指定路由规则
	// path 是 hash 地址，component 是要展示的组件
	routes: [{
			path: '/',
			component: IndexPage,
			name: 'index',
		},
		{
			path: '/productInfo',
			component: ProductInfo,
			name: 'productInfo'
		},
		{
			path: '/CoachInfo',
			component: CoachInfo,
			name: 'CoachInfo'
		},

		{
			path: '/AboutInfo',
			component: AboutInfo,
			name: 'AboutInfo'
		},
	],
})

export default router;